import { template as template_b35f9e522b5b454597e3b9f2af42abc6 } from "@ember/template-compiler";
const WelcomeHeader = template_b35f9e522b5b454597e3b9f2af42abc6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
