import { template as template_37cdb197a5c545518e44b01939107cac } from "@ember/template-compiler";
const FKLabel = template_37cdb197a5c545518e44b01939107cac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
